import { motion } from 'framer-motion';
import { useEffect, useRef, useState, useContext } from 'react';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';

const textVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

function LoveStore() {
  const ref = useRef(null);
  const { marriageCardData } = useContext(MarriageContext);
  const { globalCtx } = useContext(GlobalContext);
  const [isInView, setIsInView] = useState(false);

  const { section_love_story } = marriageCardData;

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after the first intersection
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <div
      className="w-full h-screen"
      style={{
        backgroundColor: section_love_story.loveStorySectionBackgroundColor,
        backgroundImage: section_love_story.isLoveStorySectionUsingImage
          ? `url('${section_love_story.loveStorySectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div
        ref={ref}
        className="text-start px-10 bg-transparent h-screen flex flex-col justify-center"
      >
        <motion.p
          className="text-xl font-semibold"
          style={{ color: section_love_story.loveStorySectionTitleColor }}
          initial="hidden"
          animate={isInView ? 'visible' : 'hidden'}
          transition={{ duration: 2, delay: 0.4 }}
          variants={textVariants}
        >
          {section_love_story.loveStorySectionTitleText}
        </motion.p>
        <motion.h3
          className="text-2xl my-5 font-allura"
          style={{ color: section_love_story.loveStorySectionDescriptionColor }}
          initial="hidden"
          animate={isInView ? 'visible' : 'hidden'}
          transition={{ duration: 1, delay: 0.6 }}
          variants={textVariants}
        >
          {globalCtx.groomName} & {globalCtx.brideName}
        </motion.h3>
      </div>
    </div>
  );
}

export default LoveStore;
