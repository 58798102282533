import { useEffect, useRef, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';

import './scroll.css';
import { Link } from 'react-router-dom';

const imageOneVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
};

const imageTwoVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0 },
};

function Gallery({ setShowAllGallery }) {
  const ref = useRef(null);
  const { marriageCardData } = useContext(MarriageContext);
  const [isInView, setIsInView] = useState(false);
  const { section_gallery } = marriageCardData;

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after the first intersection
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const galleryImages = section_gallery.gallerySectionGalleries;
  return (
    <div
      className="w-full h-screen md:h-screen md:px-10 snap-center"
      style={{
        backgroundColor: section_gallery.gallerySectionBackgroundColor,
        backgroundImage: section_gallery.isGallerySectionUsingImage
          ? `url('${section_gallery.gallerySectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        scrollSnapAlign: 'center',
      }}
    >
      <h1
        className="text-4xl font-bold text-center pt-16"
        style={{ color: section_gallery.gallerySectionTitleColor }}
      >
        {section_gallery.gallerySectionTitleText}
      </h1>
      <div
        ref={ref}
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-1 mt-3 mb-10 px-1"
      >
        {galleryImages?.slice(0, 5).map((linkImg, index) => (
          <motion.div
            key={index}
            className="w-full h-[190px] md:h-[170px]"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: index * 0.3 + 0.4 }}
            variants={index % 2 === 0 ? imageOneVariants : imageTwoVariants}
          >
            <img src={linkImg} alt="" className="w-full h-full object-cover" />
          </motion.div>
        ))}
        {galleryImages.length > 0 && (
          <motion.div
            className="relative w-full h-48 md:h-[170px]"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1 }}
            variants={imageTwoVariants}
          >
            <Link onClick={() => setShowAllGallery(true)}>
              <img
                src={galleryImages[galleryImages.length - 1]}
                alt=""
                className="w-full h-full object-cover"
              />
              <span className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 text-white text-xl border border-slate-400">
                {galleryImages.length}+ more
              </span>
            </Link>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Gallery;
