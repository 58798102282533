import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const textVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

function Footer() {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_footer } = marriageCardData;

  return (
    <div
      // className="min-h-screen h-[100vh] sm:h-[100dvh]"
      style={{
        backgroundColor: section_footer.footerSectionBackgroundColor,
        backgroundImage: section_footer.isFooterSectionUsingImage
          ? `url('${section_footer.footerSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="min-h-screen  pt-16 pb-28 md:p-10 text-white flex flex-col justify-between">
        <div className="text-white md:w-1/2 px-5 text-start">
          <motion.h1
            className="text-6xl font-bold"
            style={{ color: section_footer.footerSectionTitleColor }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={textVariants}
          >
            {section_footer.footerSectionTitleText}
          </motion.h1>
          <motion.p
            className="text-md md:w-2/3 my-5 font-light"
            style={{ color: section_footer.footerSectionDescriptionColor }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={textVariants}
          >
            {section_footer.footerSectionDescription}
          </motion.p>
          <motion.p
            className="text-md font-bold"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={textVariants}
          >
            #{globalCtx.groomName} & {globalCtx.brideName}
          </motion.p>
        </div>
        <div className="text-start font-bold text-white px-5">
          <motion.p initial="hidden">
            Our friendly team would love to hear from you.
          </motion.p>
          <motion.div className="flex my-5" initial="hidden">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              className="text-white"
            />
            <div className="flex-col ml-3 my-auto">
              <p>lovephoriaid@gmail.com</p>
            </div>
          </motion.div>
          <motion.div className="flex my-5" initial="hidden">
            <FontAwesomeIcon icon={faPhone} size="lg" className="text-white" />
            <div className="flex-col ml-3 my-auto">
              <p>+62 821-4434-6975</p>
            </div>
          </motion.div>
          <motion.div className="flex mb-5" initial="hidden">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              size="lg"
              className="text-white"
            />
            <div className="flex-col ml-3 my-auto">
              <p>Denpasar, Bali</p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
