import React from 'react';
import Btn from '../../components/button/btn';
import { useNavigate } from 'react-router-dom';

function AboutFooter() {
	const navigate = useNavigate();

	return (
		<div className="section flex items-center justify-center py-24  text-[#624A2D]">
			<div className="md:flex gap-6">
				<div className="md:w-1/2">
					<h2 className="text-start text-4xl md:p-10 font-bold">
						Buat Undangan Pernikahan Anda Sekarang!
					</h2>
				</div>
				<div className=" flex flex-col items-start justify-start md:w-1/2 pt-10 gap-6">
					<p className="text-md text-start w-10/12">
						Jangan lewatkan kesempatan untuk merancang undangan digital yang akan memikat tamu Anda. Mulai
						sekarang dan jadikan hari spesial Anda tak terlupakan!
					</p>
					<Btn
						colorBg={'bg-[#B07C92]'}
						title={'Mulai Desain Sekarang'}
						colorText={'text-white'}
						className={'px-7 py-4 '}
						onClick={() => navigate('/hubungi-kami')}
					/>
				</div>
			</div>
		</div>
	);
}

export default AboutFooter;
