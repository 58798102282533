import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import AnimatedButton from '../button/buttonScale';
import { GlobalContext } from '../../config/context/global';
import { MarriageContext } from '../../config/context/marriagedCard';
import { formatDate } from '../../utils/formatDate';
import styles from './cardOne.module.css';

// Animation variants
const transitionVariants = {
  initial: {
    y: '0%',
    height: '100%',
  },
  animate: {
    y: '0%',
    height: '100%',
  },
  exit: {
    y: ['0%', '0%'],
    height: ['100%', '0%'],
  },
};

const textVariants = {
  initial: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

const Transition = ({ onClose, setIsPlaying }) => {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_cover, section_intro } = marriageCardData;

  const handleButtonClick = () => {
    onClose();
    setIsPlaying(false); // Stop audio
  };
  return (
    <motion.div
      className={`flex w-full flex-col z-40 items-center justify-between h-screen py-28 md:py-10 ${styles.bgResponsive}`}
      style={{
        '--bg-color': section_cover.coverSectionBackgroundColor,
        '--desktop-bg': section_cover.isCoverSectionUsingImage
          ? `url('${section_cover.coverSectionBackgroundImage}')`
          : 'none',
        '--mobile-bg': section_intro.isIntroSectionUsingImage
          ? `url('${section_intro.introSectionBackgroundImage}')`
          : 'none',
      }}
      variants={transitionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ delay: 0.01, duration: 0.5, ease: 'easeInOut' }}
    >
      <motion.div
        className="text-white md:py-10"
        variants={textVariants}
        initial="initial"
        exit="exit"
        style={{ color: section_cover.coverSectionDescriptionColor }}
      >
        <motion.p className="text-lg">
          {section_cover.coverSectionTitleText}
        </motion.p>
        <motion.p className="text-lg">Our Wedding</motion.p>
        <motion.h1
          className="text-4xl py-2 font-allura"
          style={{ color: section_cover.coverSectionTitleColor }}
        >
          {globalCtx.groomName} & {globalCtx.brideName}
        </motion.h1>
        <motion.p className="py-1 text-lg">
          {formatDate(marriageCardData.unixWeddingDate)}
        </motion.p>
      </motion.div>
      <motion.div
        className="text-white flex flex-col justify-end my-20"
        variants={textVariants}
        initial="initial"
        style={{ color: section_cover.coverSectionDescriptionColor }}
        exit="exit"
      >
        <motion.p className="text-xl">Dear,</motion.p>
        <motion.h1
          className="pb-5 text-3xl"
          style={{ color: section_cover.coverSectionTitleColor }}
        >
          {globalCtx.invitedPeople}
        </motion.h1>
        <AnimatedButton title={'Buka undangan'} onClick={handleButtonClick} />
      </motion.div>
    </motion.div>
  );
};

export default Transition;
