import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import IntroTransition from '../transition/cardOnebanner';
import AnimatedIcon from '../button/iconScale';
import { GlobalContext } from '../../config/context/global';
import { MarriageContext } from '../../config/context/marriagedCard';
import './scroll.css';
import { formatDate } from '../../utils/formatDate';

const textVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

const TransitionBanner = ({
  isTransitionVisible,
  handleClose,
  setIsPlaying,
}) => {
  return (
    <AnimatePresence mode="wait">
      {isTransitionVisible && (
        <IntroTransition onClose={handleClose} setIsPlaying={setIsPlaying} />
      )}
    </AnimatePresence>
  );
};

function Section({
  isTransitionVisible,
  setIsTransitionVisible,
  handleClose,
  setIsPlaying,
  isCover,
}) {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_intro, section_cover } = marriageCardData;

  if (!isCover) {
    setIsTransitionVisible(false);
  }
  return (
    <div
      className={`h-screen snap-center relative`}
      style={{
        backgroundColor: section_intro.introSectionbackgroundColor,
        backgroundImage: section_intro.isIntroSectionUsingImage
          ? `url('${section_cover.coverSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {isCover && (
        <TransitionBanner
          isTransitionVisible={isTransitionVisible}
          handleClose={handleClose}
          setIsPlaying={setIsPlaying}
        />
      )}
      <div className="flex flex-col justify-between text-white py-20 h-screen">
        <div style={{ color: section_intro.introSectionDescriptionColor }}>
          <motion.h2
            className="text-lg"
            initial="hidden"
            animate={!isTransitionVisible || !isCover ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.8 }}
            variants={textVariants}
          >
            {section_intro.introSectionTitleText}
          </motion.h2>
          <motion.p
            className="text-lg"
            initial="hidden"
            animate={!isTransitionVisible || !isCover ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1 }}
            variants={textVariants}
          >
            Our Wedding
          </motion.p>
          <motion.h2
            className="text-5xl py-5 font-allura"
            initial="hidden"
            animate={!isTransitionVisible || !isCover ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1.5 }}
            variants={textVariants}
            style={{ color: section_intro.introSectionTitleColor }}
          >
            {globalCtx.groomName} & {globalCtx.brideName}
          </motion.h2>
          <motion.p
            className="py-2 text-lg"
            initial="hidden"
            animate={!isTransitionVisible || !isCover ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1.5 }}
            variants={textVariants}
          >
            {formatDate(marriageCardData.unixWeddingDate) ||
              'Wednesday / 21 / 08 / 2024'}
          </motion.p>
        </div>
        <div className="flex justify-center mb-32 md:m-0">
          <AnimatedIcon />
        </div>
      </div>
    </div>
  );
}

export default Section;
