import Btn from '../button/btn';

function Sosmed({
  sosmedName,
  name,
  fatherName,
  description,
  bgImage,
  title,
  isUsingBgImage,
  bgColor,
  btnColor,
  btnTextColor,
  descriptionColor,
  titleColor,
}) {
  return (
    <>
      <div
        className="hidden md:flex h-screen p-10 text-[#8f7c7c] text-start items-center"
        style={{ backgroundColor: bgColor }}
      >
        <div className="w-1/2 h-[70vh] flex justify-center bg-slate-500">
          <img
            src={bgImage}
            alt={`${name} img`}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-1/2 h-[70vh] p-16">
          <p className="font-bold text-xl" style={{ color: titleColor }}>
            {title}
          </p>
          <h1 className="text-5xl font-bold my-3">{name}</h1>
          <hr className="h-1 w-48 bg-white my-3" />
          <p className="w-full" style={{ color: descriptionColor }}>
            {description}
          </p>
          <Btn
            title={'@' + sosmedName}
            colorBg={{ backgroundColor: btnColor }}
            colorText={{ color: btnTextColor }}
            className="text-xl my-3"
          />
        </div>
      </div>
      <div
        className="flex h-screen md:hidden"
        style={{
          backgroundColor: bgColor,
          backgroundImage: isUsingBgImage ? `url('${bgImage}')` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          scrollSnapAlign: 'center',
        }}
      >
        <div className="text-white bg-black bg-opacity-50 text-start w-full px-10 py-36 flex flex-col justify-end items-start">
          <p className="font-bold text-xl" style={{ color: titleColor }}>
            {title}
          </p>
          <h1 className="text-3xl font-bold my-3">{name}</h1>
          <hr className="h-1 w-28 bg-white my-3" />
          <p style={{ color: descriptionColor }}>{description}</p>
          <a
            href={`https://instagram.com/${sosmedName}`}
            target="_blank"
            rel="noreferrer"
          >
            <Btn
              title={'@' + sosmedName}
              colorBg={{ backgroundColor: btnColor }}
              colorText={{ color: 'text-white' }}
              className="text-xl my-3"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default Sosmed;
