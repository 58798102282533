import { Link } from 'react-router-dom';

const ServerMaintenance = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-6xl font-bold mb-4">🚧</h1>
      <p className="text-3xl font-semibold mb-4">
        We're currently undergoing maintenance
      </p>
      <p className="text-xl mb-8">
        Our servers are currently down for maintenance. We're working hard to
        get everything back online.
        <br />
        Please check back later.
      </p>
    </div>
  );
};

export default ServerMaintenance;
