import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../config/context/global';
import { MarriageContext } from '../../config/context/marriagedCard';

const transitionvariants = {
  initial: {
    x: '0%',
    width: '100%',
  },
  animate: {
    x: '0%',
    width: ['100%'],
  },
  exit: {
    x: ['0%', '0%'],
    width: [
      '100%',
      '90%',
      '80%',
      '70%',
      '60%',
      '50%',
      '40%',
      '30%',
      '20%',
      '10%',
      '0',
    ],
  },
};

const typingVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const letterVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function TypingText({ text, onComplete }) {
  const { marriageCardData } = useContext(MarriageContext);
  return (
    <motion.p
      variants={typingVariants}
      initial="hidden"
      animate="visible"
      onAnimationComplete={onComplete}
      className="pb-2 text-xl md:text-2xl text-white font-medium"
    >
      {text.split('').map((letter, index) => {
        // bug last caracter no animation
        return (
          <motion.span
            key={index}
            variants={letterVariants}
            className={marriageCardData.section_intro.introSectionTitleColor}
          >
            {letter}
          </motion.span>
        );
      })}
    </motion.p>
  );
}

function TransitionIntro({ setShowIntro }) {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const [showText, setShowText] = useState(true);

  const handleAnimationComplete = () => {
    setTimeout(() => {
      setShowText(false);
      setShowIntro(false);
    }, 500); // Add a delay before hiding the text, if needed
  };

  return (
    <motion.div
      className="fixed z-50 w-full h-screen bg-black"
      variants={transitionvariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ delay: 0.1, duration: 0.5, ease: 'easeIn' }}
    >
      {showText && (
        <div className="relative top-[40%]">
          <TypingText
            text={marriageCardData.section_intro.introSectionTitleText}
          />
          <TypingText
            text={`Mr. ${globalCtx.groomName} & Mrs. ${globalCtx.brideName}`}
            onComplete={handleAnimationComplete}
          />
        </div>
      )}
    </motion.div>
  );
}

export default TransitionIntro;
