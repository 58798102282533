import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const IDIG = process.env.REACT_APP_INSTAGRAM_NAME || '';

function Footer() {
	return (
		<div className='md:pt-16 bg-[#3A2414] z-50 text-white md:footer'>
			<div className='flex flex-col gap-20 mx-5 md:mx-0'>
				<div className='md:flex justify-between  '>
					<div className='text-start '>
						<div className='py-5 text-2xl font-bold'>
							<img
								src='assets/images/logo.png'
								alt='logo'
								width={100}
							/>
						</div>
						<div className='my-5'>
							<p className='font-bold '>Address:</p>
							<p>Denpasar, bali</p>
						</div>
						<div className='flex flex-col'>
							<p className='font-bold'>Contact:</p>
							<a
								href='tel:+62 821-4434-6975'
								className='underline underline-offset-1'
							>
								+62 821-4434-6975
							</a>
							<a
								href='mailto:lovephoriaid@gmail.com'
								className='underline underline-offset-1'
							>
								lovephoriaid@gmail.com
							</a>
						</div>
						<div className='flex gap-3 my-5'>
							<a
								href={`https://instagram.com/${IDIG}`}
								target='_blank'
								rel='noreferrer'
							>
								<FontAwesomeIcon
									icon={faInstagram}
									className='text-2xl'
								/>
							</a>
						</div>
					</div>
					<div className='flex md:w-1/3 justify-between text-start md:px-20 md:py-2'>
						<div className='flex flex-col gap-y-4'>
							<a href='/'>Beranda</a>
							<a href='/collection'>Koleksi</a>
							{/* <a href='/pricing'>Pricing</a>
							<a href='/collection'>Testimonial</a>
							<a href='/contactUs'>Contact Us</a> */}
						</div>
					</div>
				</div>

				{/* <hr className=" h-0.5 bg-black m-auto" /> */}
				<div className=' md:flex md:flex-row-reverse justify-between py-10 border-t-2 '>
					<div className='text-start my-5 md:my-0 md:flex gap-5'>
						<a
							href='/privacy-and-policy'
							className='block my-3 md:my-0 underline underline-offset-1'
						>
							Privacy Policies
						</a>

						<a
							href='/term-conditions'
							className='block my-3 md:my-0 underline underline-offset-1'
						>
							Terms & Conditions
						</a>
					</div>
					<div className=''>
						<p>
							&copy; {new Date().getFullYear()} Lovephoria. All rights reserved. Created by{' '}
							<a
								className='underline'
								href='http://www.triplepixelsagency.com'
							>
								Triple Pixels Agency
							</a>
							.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
