import { useContext, useEffect } from 'react';

import Header from '../layout/header';
// import HeroSection from '../layout/header/test';
import Service from '../layout/service';
import Portofolio from '../layout/portofolio';
// import Testimonial from '../layout/testimonial';
import AboutFooter from '../layout/aboutFooter';
import Price from '../layout/price/home';
import Fitur from '../layout/fitur';

import { MarriageContext } from '../config/context/marriagedCard';
import { getAll } from '../api';
import WhatsappButton from '../components/button/btnWa';
// import { Helmet } from 'react-helmet-async';

function Home() {
  const { marriageCardData, setMarriageCardData } = useContext(MarriageContext);
  const fetchData = async () => {
    try {
      const { data } = await getAll({
        url: `marriage-card/list/active/highlight`,
      });
      setMarriageCardData(data);
    } catch (error) {
      console.error('fetching marriage-card');
      window.location.href = '/maintenance';
    }
  };
  useEffect(() => {
    fetchData();
  }, [setMarriageCardData]);
  return (
    <div className="overflow-auto">
      {/* <Helmet>
        <title>{'home'}</title>
        <meta
          property="og:image"
          content={'https://storage.googleapis.com/lovephoria/IMG_5783.JPG'}
        />
        <meta property="type" content="website" />
      </Helmet> */}
      <Header />
      <Fitur />
      <Service />
      {marriageCardData && <Portofolio />}
      <Price />
      {/* <Testimonial /> */}
      <AboutFooter />
      <WhatsappButton
        phoneNumber={'+6282144346975'}
        message={
          'Halo Lovephoria Invitation! Saya ingin tahu lebih banyak tentang layanan undangan pernikahan digital Anda.'
        }
      />
    </div>
  );
}

export default Home;
