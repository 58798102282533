// import ImageCard from '../../components/template3/imageCard';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// import { GlobalContext } from '../../config/context/global';
import { SubscriptionContext } from '../../config/context/subscription';
import { MarriageContext } from '../../config/context/marriagedCard';

// components
import UcapanCard from '../../components/template3/ucapan';
import Gallery from '../../components/template3/gallery';
import IntroTransition from '../../components/transition/cardThreebanner';
import Btn from '../../components/button/btnCustom';

const imageVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};
const btnVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const scaleVariants = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
};

const TransitionBanner = ({
  isTransitionVisible,
  handleClose,
  setIsPlaying,
}) => {
  return (
    <AnimatePresence mode="wait">
      {isTransitionVisible && (
        <IntroTransition onClose={handleClose} setIsPlaying={setIsPlaying} />
      )}
    </AnimatePresence>
  );
};

const calculateTimeLeft = (unixDate) => {
  const difference = unixDate - Date.now();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

function TemplateThree() {
  const [isInView, setIsInView] = useState({});
  const refs = useRef([]);
  const [isTransitionVisible, setIsTransitionVisible] = useState(true);
  // const [showIntro, setShowIntro] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  // const { globalCtx } = useContext(GlobalContext);
  const { subscriptionData } = useContext(SubscriptionContext);
  const { marriageCardData } = useContext(MarriageContext);
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(marriageCardData.unixWeddingDate)
  );
  const {
    section_intro,
    // section_countdown,
    section_bride,
    section_groom,
    section_agenda,
    section_digital_wallet,
    section_dresscode,
    section_footer,
    section_gallery,
    section_love_story,
    section_prewedding,
  } = marriageCardData;
  const handleClose = () => setIsTransitionVisible(false);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };
  const audioRef = useRef(null);

  const handleAudioToggle = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          console.error(error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };
  // const handleContextMenu = (e) => {
  // 	e.preventDefault();
  // };
  const expiresDate = marriageCardData.unixWeddingDate < Date.now();
  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener('ended', () => setIsPlaying(false));
    const currentRefs = refs.current;
    const observers = currentRefs.map((ref, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsInView((prev) => ({
            ...prev,
            [index]: entry.intersectionRatio >= 0.1,
          }));
        },
        {
          threshold: 0.1,
        }
      );

      if (ref) {
        observer.observe(ref);
      }

      return observer;
    });
    if (isTransitionVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    let timer;
    // document.addEventListener('contextmenu', handleContextMenu);
    if (expiresDate) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(marriageCardData.unixWeddingDate));
      }, 1000);
    }

    return () => {
      audioElement.removeEventListener('ended', () => setIsPlaying(false));
      // document.removeEventListener('contextmenu', handleContextMenu);
      document.body.style.overflow = 'auto';
      observers.forEach((observer, index) => {
        if (currentRefs[index]) {
          observer.unobserve(currentRefs[index]);
        }
      });
      clearInterval(timer);
    };
  }, [isTransitionVisible, marriageCardData.unixWeddingDate, expiresDate]);

  return (
    <div
      // onContextMenu={handleContextMenu}
      className={`${
        isTransitionVisible ? 'overflow-hidden' : 'overflow-hidden'
      } h-full`}
    >
      <TransitionBanner
        isTransitionVisible={isTransitionVisible}
        handleClose={handleClose}
        setIsPlaying={handleAudioToggle}
      />
      <audio
        ref={audioRef}
        src={marriageCardData.weddingMusic || '/assets/audio/audio.mpeg'}
        loop
        muted={marriageCardData.weddingMusic ? false : true}
      />
      <button
        onClick={handleAudioToggle}
        className="fixed bottom-5 left-5 text-white z-30 bg-transparent text-2xl rounded-lg"
      >
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
      </button>
      {/* Intro Section */}
      {subscriptionData.isUsingIntroSection && (
        <div key={0} ref={addToRefs} className={`h-screen md:h-fit bg-white`}>
          <motion.div
            className="flex flex-col h-fit md:h-fit justify-between text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[0] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_intro.introSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full"
            />
          </motion.div>
        </div>
      )}
      {/* Groom Section */}
      {subscriptionData.isUsingGroomSection && (
        <div key={1} ref={addToRefs} className={`h-screen md:h-fit bg-white`}>
          <motion.div
            className="flex flex-col h-fit md:h-fit justify-between text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[1] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_groom.groomSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full"
            />
          </motion.div>
        </div>
      )}
      {/* Bride Section */}
      {subscriptionData.isUsingBrideSection && (
        <div key={2} ref={addToRefs} className={`h-fit bg-white`}>
          <motion.div
            className="flex flex-col h-fit md:h-fit justify-between text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[2] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_bride.brideSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full"
            />
          </motion.div>
        </div>
      )}
      {/* Love Story Section */}
      {subscriptionData.isUsingLoveStorySection && (
        <div key={3} ref={addToRefs} className={`h-fit bg-white`}>
          <motion.div
            className="flex flex-col h-screen md:h-fit justify-between text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[3] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_love_story.loveStorySectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full"
            />
          </motion.div>
        </div>
      )}
      {/* Agenda Section */}
      {subscriptionData.isUsingAgendaSection && (
        <div key={4} ref={addToRefs} className="h-fit">
          <motion.div
            className="flex relative flex-col h-fit md:h-fit items-center text-white bg-[#3A241480] w-full"
            initial="hidden"
            animate={isInView[4] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <motion.div
              initial="hidden"
              animate={isInView[4] ? 'visible' : 'hidden'}
              transition={{ duration: 1, delay: 1.5 }}
              variants={btnVariants}
              className="absolute bottom-[20%] lg:bottom-[23%] w-2/5 lg:w-1/3 py-3 z-30 text-xl "
            >
              <Btn
                title={'Location'}
                icon={faMapMarkerAlt}
                colorText={`text-${section_agenda.agendaSectionTitleColor}`}
                colorBg={'bg-transparent'}
                className={'w-full py-3 text-xl border border-gray-600'}
                onClick={() =>
                  window.open(section_agenda.agendaSectionMapsKedua)
                }
              />
            </motion.div>
            <img
              src={section_agenda.agendaSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full"
              width="100%"
            />
          </motion.div>
        </div>
      )}
      {/* Dress Code Section */}
      {subscriptionData.isUsingDressCodeSection && (
        <div key={5} ref={addToRefs} className={`h-fit bg-white`}>
          <motion.div
            className="flex flex-col h-screen md:h-fit justify-between text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[5] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_dresscode.dressCodeSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full"
            />
          </motion.div>
        </div>
      )}
      {/* Gallery */}
      {/* <Gallery galleryData={section_galleryData} /> */}
      {/* {subscriptionData.isUsingGallerySection && (
				<Gallery galleryData={section_gallery.gallerySectionGalleries} />
			)} */}
      <Gallery galleryData={section_gallery.gallerySectionGalleries} />

      {/* Ucapan Card */}
      {subscriptionData.isUsingRSVPSection && <UcapanCard />}

      {/* dressCode */}
      {subscriptionData.isUsingDressCodeSection && (
        <div key={6} ref={addToRefs} className={`h-fit bg-white`}>
          <motion.div
            className="flex relative h-screen md:h-fit justify-center text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[6] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_dresscode.dressCodeSectionBackgroundImage}
              // src={'/assets/images/tmplate31.jpg'}
              alt=""
              className="h-screen object-cover md:h-full w-full"
            />
          </motion.div>
        </div>
      )}

      {/* digital Wallet */}
      {subscriptionData.isUsingDigitalWalletSection && (
        <div key={7} ref={addToRefs} className={``}>
          <motion.div
            className="flex relative h-screen md:h-fit justify-center text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[7] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <img
              src={section_digital_wallet.digitalWalletSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full w-full"
            />
          </motion.div>
        </div>
      )}

      {/* Prewedding video */}
      {subscriptionData.isUsingPreweddingVideo && (
        <div key={8} ref={addToRefs} className={`h-fit bg-white`}>
          <motion.div
            className="flex relative h-screen md:h-fit justify-center text-white bg-[#3A241480]"
            initial="hidden"
            animate={isInView[8] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={imageVariants}
          >
            <motion.div
              initial="hidden"
              animate={isInView[8] ? 'visible' : 'hidden'}
              transition={{ duration: 2, delay: 0.4 }}
              variants={scaleVariants}
              className="absolute bottom-1/3 w-11/12 py-4 z-30 text-xl bg-black bg-opacity-75 flex justify-center md:px-28 px-10"
            >
              <video
                controls
                className="relative w-full rounded-xl"
                loop
                autoPlay
                muted
              >
                <source
                  src={section_prewedding.preweddingSectionVideo}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </motion.div>
            <img
              src={section_prewedding.preweddingSectionBackgroundImage}
              alt=""
              className="h-screen object-cover md:h-full w-full"
            />
          </motion.div>
        </div>
      )}

      {/* Countdown Timer */}
      <div key={9} ref={addToRefs} className="h-fit md:h-screen">
        <motion.div
          className="flex relative flex-col h-fit md:h-fit items-center text-white bg-[#3A241480]"
          initial="hidden"
          animate={isInView[9] ? 'visible' : 'hidden'}
          transition={{ duration: 1, delay: 0.4 }}
          variants={imageVariants}
        >
          {subscriptionData.isUsingCountdownSection &&
            (!expiresDate ? (
              <motion.div
                initial="hidden"
                animate={isInView[9] ? 'visible' : 'hidden'}
                transition={{ duration: 2, delay: 1.5 }}
                variants={btnVariants}
                className="absolute bottom-1/2 w-11/12 py-4 z-30 text-xl bg-black bg-opacity-75 flex justify-between md:px-28 px-10"
              >
                <div className="text-sm md:text-lg font-bold">
                  <p>Days</p>
                  <p className="font-roboto ">{timeLeft.days}</p>
                </div>
                <div className="text-sm md:text-lg font-bold">
                  <p>Hours</p>
                  <p className="font-roboto">{timeLeft.hours}</p>
                </div>
                <div className="text-sm md:text-lg font-bold">
                  <p>Minutes</p>
                  <p className="font-roboto">{timeLeft.minutes}</p>
                </div>
                <div className="text-sm md:text-lg font-bold">
                  <p>Seconds</p>
                  <p className="font-roboto">{timeLeft.seconds}</p>
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial="hidden"
                animate={isInView[9] ? 'visible' : 'hidden'}
                transition={{ duration: 2, delay: 1.5 }}
                variants={btnVariants}
                className="absolute bottom-1/2 w-11/12 py-4 z-30 text-xl bg-black bg-opacity-75 flex justify-center md:px-28 px-10"
              >
                <h1>Telah Berakhir</h1>
              </motion.div>
            ))}

          <img
            src={section_footer.footerSectionBackgroundImage}
            alt=""
            className="w-full h-screen object-cover "
          />
          <motion.div
            initial="hidden"
            animate={isInView[9] ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1 }}
            variants={btnVariants}
            className="absolute bottom-8 w-11/12 py-4 z-30 text-xl flex justify-center gap-5 md:px-28 px-10"
          >
            <a
              href="https://instagram.com/love_phoria"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-3xl"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://wa.me/+6282144346975"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-3xl"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

export default TemplateThree;
