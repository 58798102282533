import { useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import Btn from '../button/btn';

// Variabel animasi
const transitionVariants = {
  initial: {
    y: '0%',
    height: '100%',
  },
  animate: {
    y: '0%',
    height: '100%',
  },
  exit: {
    y: ['0%', '0%'],
    height: ['100%', '0%'],
  },
};

const textVariants = {
  initial: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

const TransitionCardThree = ({ onClose, setIsPlaying }) => {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_cover } = marriageCardData;
  const handleButtonClick = () => {
    onClose();
    setIsPlaying(true);
  };
  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center z-40"
      variants={transitionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ delay: 0.01, duration: 0.5, ease: 'easeInOut' }}
    >
      <motion.div
        className="flex flex-col items-center md:w-2/5 justify-end py-5 rounded-md text-black mx-4 my-8"
        style={{
          backgroundColor: section_cover.coverSectionBackgroundColor,
          backgroundImage: section_cover.isCoverSectionUsingImage
            ? `url('${section_cover.coverSectionBackgroundImage}')`
            : 'none',
          // backgroundImage: `url(https://undanganpektif.com/assets/undangan/website/widanta-ayu/background1693983227678372261.jpg)`,
          backgroundSize: '100%',
          backgroundPosition: 'top',
          height: '90vh',
          overflow: 'auto',
        }}
        variants={textVariants}
        initial="initial"
        exit="exit"
      >
        <motion.div className=" flex w-full pb-10  flex-col items-center mt-4 px-2">
          <motion.p>
            Kepada <br /> Yth. Bapak/Ibu/Saudara/i
          </motion.p>
          <motion.div className=" bg-black bg-opacity-70 text-white w-full rounded-md py-2 my-2">
            <h1 className="text-2xl font-bold">{globalCtx.invitedPeople} </h1>
          </motion.div>
          <motion.p className="text-sm mt-2 md:text-md">
            Tanpa mengurangi rasa hormat, kami bermaksud untuk mengundang
            Bapak/Ibu/Saudara/i untuk hadir pada:
          </motion.p>
          <motion.div className="bg-[#000] bg-opacity-65 text-white w-full rounded-md py-2 my-1 md:text-md font-semibold">
            <p>{marriageCardData.weddingDateText} </p>
            <p>{marriageCardData.agendaSectionHoursPertama}</p>
          </motion.div>
          <Btn
            title={'Buka Undangan'}
            colorBg={'bg-black'}
            colorText={'text-white'}
            className={
              'w-full hover:bg-black hover:bg-opacity-50 hover:text-white hover:shadow-md hover:border hover:border-white transition-all duration-300 ease-in-out py-2 text-xl bg-opacity-65'
            }
            onClick={handleButtonClick}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default TransitionCardThree;
