function Header() {
	return (
		<div className='md:w-full md:h-full pt-32 flex flex-col  items-center justify-center text-[#3A2414] gap-6'>
			<div className='md:w-[768px] flex flex-col justify-center text-center items-center gap-6'>
				<h1 className='text-4xl md:text-4xl  md:w-full mx-auto font-semibold '>
					Ceritakan Tentang Desain Kartu Undangan Pernikahan Anda
				</h1>
				<p className='text-lg'>Ungkapkan keunikan dan cerita di balik setiap kartu undangan yang dirancang khusus untuk momen berharga.</p>
			</div>
		</div>
	);
}

export default Header;
