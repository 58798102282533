import React, { useContext, useEffect } from 'react';
import Header from '../layout/collection/header';
import AboutFooter from '../layout/aboutFooter';
import Collections from '../layout/collection/collection';
import { MarriageContext } from '../config/context/marriagedCard';
import { getAll } from '../api';
import WhatsappButton from '../components/button/btnWa';

function Collection() {
  const { marriageCardData, setMarriageCardData } = useContext(MarriageContext);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAll({
        url: `marriage-card/list/active/showable`,
      });
      setMarriageCardData(data);
    };
    fetchData();
  }, [setMarriageCardData]);
  return (
    <div className="overflow-auto">
      <Header />
      {marriageCardData && <Collections />}
      <AboutFooter />
      <WhatsappButton
        phoneNumber={'+6282144346975'}
        message={
          'Halo Lovephoria Invitation! Saya ingin tahu lebih banyak tentang layanan undangan pernikahan digital Anda.'
        }
      />
    </div>
  );
}

export default Collection;
