import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { MarriageContext } from '../../config/context/marriagedCard';

const scaleVariants = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
};

function VideoWedding() {
  const ref = useRef(null);
  const { marriageCardData } = useContext(MarriageContext);
  const [isInView, setIsInView] = useState(false);
  const { section_prewedding } = marriageCardData;

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after the first intersection
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <div
      ref={ref}
      className="flex flex-col md:flex-row h-screen text-white items-center px-5 justify-start pt-48 md:pt-0"
      style={{
        backgroundColor: section_prewedding.preweddingSectionBackgroundColor,
        backgroundImage: section_prewedding.isPreweddingSectionUsingImage
          ? `url('${section_prewedding.preweddingSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        scrollSnapAlign: 'center',
      }}
    >
      <div className="w-full flex flex-col justify-center">
        <div
          className="my-5 text-2xl"
          style={{ color: section_prewedding.preweddingSectionTitleColor }}
        >
          <p>{section_prewedding.preweddingSectionTitleText}</p>
        </div>
        <motion.div
          className="w-full h-fit md:my-auto"
          initial="hidden"
          animate={isInView ? 'visible' : 'hidden'}
          transition={{ duration: 2, delay: 0.4 }}
          variants={scaleVariants}
        >
          <video
            // ref={videoRef}
            controls
            className="w-full rounded-xl"
            loop
            autoPlay
            muted
            playsInline
          >
            <source
              src={section_prewedding.preweddingSectionVideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </motion.div>
        <div
          className="relative z-10 -rotate-6 w-10/12 text-start text-2xl -mt-10 font-allura"
          style={{
            color: section_prewedding.preweddingSectionDescriptionColor,
          }}
        >
          <p>{section_prewedding.preweddingSectionDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default VideoWedding;
