import { useContext, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import Btn from '../button/btn';

const accountVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

function GiftLove() {
  const [showAccount, setShowAccount] = useState(false);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_digital_wallet } = marriageCardData;
  const divRef = useRef(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard: ' + text);
  };

  const handleClick = () => {
    setShowAccount(!showAccount);
  };
  // const handleFocus = (e) => {
  //   window.scrollTo(0, 0);
  // };

  useEffect(() => {
    const currentRef = divRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          window.scrollTo(0, 0);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={divRef}
      className="w-full h-screen"
      style={{
        backgroundColor:
          section_digital_wallet.digitalWalletSectionBackgroundColor,
        backgroundImage: section_digital_wallet.isDigitalWalletSectionUsingImage
          ? `url('${section_digital_wallet.digitalWalletSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="h-screen p-5 md:p-20 text-white text-center relative top-36 md:top-1/4 lg:top-1/4 ">
        <div className="flex flex-col md:flex-row w-full text-start justify-start items-center gap-5 md:gap-20">
          <div
            className="text-6xl font-bold md:mx-10 max-w-md "
            style={{
              color: section_digital_wallet.digitalWalletSectionTitleColor,
            }}
          >
            <h1>{section_digital_wallet.digitalWalletSectionTitleText}</h1>
          </div>
          <div
            className={`md:py-6 text-center md:text-start ${
              showAccount ? 'w-full' : 'w-full md:w-1/3'
            } rounded-lg`}
          >
            <p
              style={{
                color:
                  section_digital_wallet.digitalWalletSectionDescriptionColor,
              }}
            >
              {section_digital_wallet.digitalWalletSectionDescription}
            </p>
            <Btn
              title="CLICK HERE"
              colorText={`text-${section_digital_wallet.digitalWalletSectionButtonTextColor}`}
              colorBg={`bg-${section_digital_wallet.digitalWalletSectionButtonColor}`}
              className="my-10 md:text-xl px-5 border-2 border-white rounded-full"
              onClick={handleClick}
            />
          </div>
          <AnimatePresence>
            {showAccount && (
              <motion.div
                className="w-full flex items-center justify-between p-4 space-x-1 bg-transparent text-white border-b-2"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={accountVariants}
                transition={{ duration: 0.5 }}
                style={{
                  color:
                    section_digital_wallet.digitalWalletSectionDescriptionColor,
                }}
              >
                <div className="flex flex-col h-fit max-w-40 text-start break-words leading-tight">
                  <span className="text-sm">
                    {section_digital_wallet.digitalWalletSectionBankName} an.
                  </span>
                  <span className="w-full -mt-1">
                    {section_digital_wallet.digitalWalletSectionAccountHolder ||
                      'anonymous'}
                  </span>
                </div>
                <span>
                  {section_digital_wallet.digitalWalletSectionRekeningNumber}
                </span>
                <button
                  className="px-2 py-1 rounded-lg"
                  style={{
                    backgroundColor:
                      section_digital_wallet.digitalWalletSectionButtonColor,
                    color:
                      section_digital_wallet.digitalWalletSectionButtonTextColor,
                  }}
                  onClick={() =>
                    copyToClipboard(
                      section_digital_wallet.digitalWalletSectionRekeningNumber
                    )
                  }
                >
                  Copy
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default GiftLove;
