import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { GlobalContext } from '../../config/context/global';
import { MarriageContext } from '../../config/context/marriagedCard';
import { formatDate } from '../../utils/formatDate';

// variable
const transitionvariants = {
  initial: {
    y: '0%',
    height: '100%',
  },
  animate: {
    y: '0%',
    height: '100%',
  },
  exit: {
    y: ['0%', '0%'],
    height: ['100%', '0%'],
  },
};

const Transition = ({ onClose, setIsPlaying }) => {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_cover } = marriageCardData;

  const handleButtonClick = () => {
    onClose();
    setIsPlaying(false); // Assuming this stops the audio
  };
  return (
    <>
      <motion.div
        className="fixed flex w-full flex-col z-40 items-center justify-between h-screen pb-20 px-3 md:py-5"
        style={{
          backgroundColor: section_cover.coverSectionBackgroundColor,
          backgroundImage: section_cover.isCoverSectionUsingImage
            ? `url('${section_cover.coverSectionBackgroundImage}')`
            : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        variants={transitionvariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ delay: 0.1, duration: 0.5, ease: 'easeIn' }}
      >
        <motion.div
          className="text-white my-10"
          variants={transitionvariants}
          style={{ color: section_cover.coverSectionDescriptionColor }}
        >
          <p className="font-bold">{section_cover.coverSectionTitleText}</p>
          <h1
            className="text-6xl my-3 font-bold"
            style={{ color: section_cover.coverSectionTitleColor }}
          >
            {globalCtx.groomName} & {globalCtx.brideName}
          </h1>
          <p className="text-md font-bold">
            {formatDate(marriageCardData.unixWeddingDate, ',').toUpperCase()}
          </p>
        </motion.div>
        <motion.div
          className="flex flex-col justify-end"
          style={{ color: section_cover.coverSectionDescriptionColor }}
          variants={transitionvariants}
        >
          <p className="text-xl">Dear,</p>
          <h1
            className="py-2 text-3xl"
            style={{ color: section_cover.coverSectionTitleColor }}
          >
            Mr. {globalCtx.invitedPeople}
          </h1>
          <p>Mohon maaf jika ada kesalahan penulisan nama & gelar</p>

          <button
            onClick={handleButtonClick}
            className="bg-[#3d3d3d] py-1 px-5 my-5 text-[#DADADA] font-bold rounded mx-auto"
          >
            OPEN INVITATION
          </button>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Transition;
