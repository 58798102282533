import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Btn from '../button/btn';
import DropdownInput from '../dropdown/dropdownInput';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import { getById, postData } from '../../api';
import Swal from 'sweetalert2';

function Rsvp() {
  const ref = useRef(null);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [activeButton, setActiveButton] = useState('Tulis Ucapan');
  const [showForm, setShowForm] = useState(true);
  const [guestMessage, setGuestMessage] = useState([]);
  const { marriageCardData } = useContext(MarriageContext);
  const { globalCtx } = useContext(GlobalContext);
  const { section_rsvp } = marriageCardData;
  const [formMessage, setFormMessage] = useState({
    RSVP: 'yes',
    name: '',
    lastName: '',
    message: '',
    visitorTotal: '',
    peopleInvitedId: globalCtx.peopleInvitedData._id,
    marriageCardId: marriageCardData.id,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormMessage((prevFormMessage) => ({
      ...prevFormMessage,
      [name]: name === 'visitorTotal' ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formMessage.name = formMessage.name + ' ' + formMessage.lastName;
      const response = await postData({
        url: `messages/add`,
        data: formMessage,
      });
      if (response.statusCode === 201) {
        // Reset the form
        setFormMessage({
          name: '',
          lastName: '',
          message: '',
          RSVP: 'yes',
          visitorTotal: '',
          peopleInvitedId: globalCtx.peopleInvitedData._id,
          marriageCardId: globalCtx.peopleInvitedData.marriageCardId,
        });
        // Refresh the messages
        const messageData = await getById({
          url: `messages/list/message/${marriageCardData.id}`,
        });
        const updatedMessages = messageData.map((guest) => ({
          ...guest,
          kehadiran:
            guest.RSVP === 'yes'
              ? 'Hadir'
              : guest.RSVP === 'no'
              ? 'Tidak hadir'
              : 'Ragu',
        }));
        setGuestMessage(updatedMessages);
      }
    } catch (error) {
      Swal.fire({
        title: 'Sorry!',
        text:
          error.response.data.message === 'PeopleInvitedId not found'
            ? 'Ini adalah undangan sampel. Pengguna tidak dapat menambahkan pesan RSVP.'
            : error?.response?.data?.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
      setFormMessage({
        name: '',
        lastName: '',
        message: '',
        RSVP: 'yes',
        visitorTotal: '',
        peopleInvitedId: globalCtx.peopleInvitedData._id,
        marriageCardId: globalCtx.peopleInvitedData.marriageCardId,
      });
      // console.error('Error submitting message:', error);
    }
  };

  const handleGuestList = (buttonType) => {
    setShowForm(!showForm);
    setShowForm(buttonType === 'Tulis Ucapan');
    setActiveButton(buttonType);
  };
  const handleFocus = () => {
    setIsKeyboardOpen(true);
    window.scrollTo(0, 0); // Scroll to top
  };

  const handleBlur = () => {
    setIsKeyboardOpen(false);
    window.scrollTo(0, 0); // Scroll to top again after keyboard closes
  };
  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const messageData = await getById({
          url: `messages/list/message/${marriageCardData.id}`,
        });
        const updatedMessages = messageData.map((guest) => ({
          ...guest,
          kehadiran:
            guest.RSVP === 'yes'
              ? 'Hadir'
              : guest.RSVP === 'no'
              ? 'Tidak hadir'
              : 'Ragu',
        }));
        setGuestMessage(updatedMessages);
      } catch (error) {
        console.error('Error fetching message data:');
      }
    };
    fetchMessage();
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [marriageCardData.id]);

  return (
    <div
      ref={ref}
      className="w-full h-screen md:h-screen px-8 py-12 md:py-5 relative"
      style={{
        paddingBottom: 'env(safe-area-inset-bottom)',
        backgroundColor: section_rsvp.rsvpSectionBackgroundColor,
        backgroundImage: section_rsvp.isRsvpSectionUsingImage
          ? `url('${section_rsvp.rsvpSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <motion.h1
        className="text-start text-4xl font-bold"
        style={{ color: section_rsvp.rsvpSectionTitleColor }}
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        transition={{ duration: 1, delay: 0.4 }}
        variants={{
          hidden: { opacity: 0, y: -20 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        {section_rsvp.rsvpSectionTitleText}
      </motion.h1>
      <motion.p
        className="text-start text-md font-light"
        style={{ color: section_rsvp.rsvpSectionDescriptionColor }}
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        transition={{ duration: 1, delay: 0.7 }}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        {section_rsvp.rsvpSectionDescriptionText}
      </motion.p>
      <div className="flex justify-center mt-5">
        <Btn
          title={'Tulis Ucapan'}
          // colorText={activeButton === 'Tulis Ucapan' ? 'text-white' : 'text-black'}
          className={`text-lg p-2 border  mr-2`}
          style={{
            backgroundColor:
              activeButton === 'Tulis Ucapan'
                ? section_rsvp.rsvpSectionButtonColor
                : '#FFFFFF',
            color:
              activeButton === 'Tulis Ucapan'
                ? section_rsvp.rsvpSectionButtonTextColor
                : '#000000',
            borderColor: section_rsvp.rsvpSectionInputBorderColor,
          }}
          onClick={() => handleGuestList('Tulis Ucapan')}
        />
        <Btn
          title={'Buku Tamu'}
          // colorText={activeButton === 'Buku Tamu' ? 'text-white' : 'text-black'}
          className={`text-lg p-2 border mr-2`}
          style={{
            backgroundColor:
              activeButton === 'Buku Tamu'
                ? section_rsvp.rsvpSectionButtonColor
                : '#FFFFFF',
            color:
              activeButton === 'Buku Tamu'
                ? section_rsvp.rsvpSectionButtonTextColor
                : '#000000',
            borderColor: section_rsvp.rsvpSectionInputBorderColor,
          }}
          onClick={() => handleGuestList('Buku Tamu')}
        />
      </div>
      {showForm ? (
        <div className="flex flex-col justify-center mt-6">
          <form
            onSubmit={handleSubmit}
            className={`transition-all ${isKeyboardOpen ? 'pb-20' : ''}`}
          >
            <label className="block">
              <span className="block text-start text-sm font-light">Name</span>
              <input
                type="text"
                name="name"
                value={formMessage.name}
                required
                onChange={handleInputChange}
                onBlur={() => {
                  window.scrollTo(0, 10);
                }}
                className={`mt-1 px-5 py-3 border shadow-sm placeholder-slate-400 focus:outline-none block w-full sm:text-sm`}
                style={{
                  backgroundColor: section_rsvp.rsvpSectionInputColor,
                  color: section_rsvp.rsvpSectionInputTextColor,
                  borderColor: section_rsvp.rsvpSectionInputBorderColor,
                }}
              />
            </label>
            <DropdownInput
              className={`mt-1 border shadow-sm placeholder-slate-400 focus:outline-none block w-full sm:text-sm`}
              style={{
                backgroundColor: section_rsvp.rsvpSectionInputColor,
                color: section_rsvp.rsvpSectionInputTextColor,
                borderColor: section_rsvp.rsvpSectionInputBorderColor,
              }}
              title="Kehadiran"
              value={formMessage.RSVP}
              onChange={(value) =>
                setFormMessage((prev) => ({ ...prev, RSVP: value }))
              }
            />
            <label className="block">
              <span className="block text-start text-sm font-medium">
                Jumlah kehadiran
              </span>
              <input
                type="number"
                name="visitorTotal"
                required
                value={formMessage.visitorTotal}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`mt-1 p-3 border shadow-sm placeholder-slate-400 focus:outline-none block w-full sm:text-sm`}
                style={{
                  backgroundColor: section_rsvp.rsvpSectionInputColor,
                  color: section_rsvp.rsvpSectionInputTextColor,
                  borderColor: section_rsvp.rsvpSectionInputBorderColor,
                }}
              />
            </label>
            <div className="text-start my-2">
              <p className="mb-2">Ucapan</p>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formMessage.message}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className="resize-none border rounded-md w-full py-2 px-3 leading-tight focus:outline-none"
                style={{
                  borderColor: section_rsvp.rsvpSectionInputBorderColor,
                  backgroundColor: section_rsvp.rsvpSectionInputColor,
                  color: section_rsvp.rsvpSectionInputTextColor,
                }}
                placeholder="Type your message here..."
                required
              ></textarea>
            </div>
            <div className="text-start">
              <Btn
                title="Submit"
                style={{
                  backgroundColor: section_rsvp.rsvpSectionButtonColor,
                  color: section_rsvp.rsvpSectionButtonTextColor,
                }}
                className="w-1/4 py-2"
                type="submit"
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col my-10 justify-center  max-h-[65vh] w-full ">
          <ul className="divide-y divide-gray-300 text-center w-full mx-auto rounded-lg bg-white overflow-auto">
            {guestMessage.map((guest, i) => (
              <li key={i} className="py-2 ">
                <h1 className="font-bold text-xl">
                  {guest.peopleInvitedId?.attendantName}
                </h1>
                <p className="px-5 mx-auto text-lg my-3">{guest.message}</p>
                <p className="text-xl">-{guest.kehadiran}-</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Rsvp;
