import { useEffect, useState, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import { formatDate } from '../../utils/formatDate';
import Btn from '../button/btn';

const textVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

function Location() {
  const ref = useRef(null);
  const { marriageCardData } = useContext(MarriageContext);
  const { globalCtx } = useContext(GlobalContext);
  const [isInView, setIsInView] = useState(false);

  const { section_agenda } = marriageCardData;
  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      className={`w-full h-screen`}
      style={{
        backgroundColor: section_agenda.agendaSectionBackgroundColor,
        backgroundImage: section_agenda.isAgendaSectionUsingImage
          ? `url('${section_agenda.agendaSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        scrollSnapAlign: 'center',
      }}
    >
      <div
        ref={ref}
        className="bg-transparent h-screen p-10  text-center flex flex-col justify-center"
      >
        <div
          className="w-full h-fit"
          style={{ color: section_agenda.agendaSectionDescriptionColor }}
        >
          <motion.p
            className="font-bold"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionTitleText}
          </motion.p>
          <motion.h1
            style={{ color: section_agenda.agendaSectionTitleColor }}
            className="font-allura text-4xl my-3"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={textVariants}
          >
            {globalCtx.groomName} & {globalCtx.brideName}
          </motion.h1>
          <motion.p
            className="font-light my-2"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.6 }}
            variants={textVariants}
          >
            {formatDate(marriageCardData.unixWeddingDate)}
          </motion.p>
          <motion.p
            className="mt-5 font-bold"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1.4, delay: 0.7 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionAcaraPertama}
          </motion.p>
          <motion.p
            className="mb-5"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1.8, delay: 0.8 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionHoursPertama}
          </motion.p>
          <motion.p
            className="font-bold"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 2.2, delay: 0.9 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionLocationPertama}
          </motion.p>
          <motion.p
            className="font-light"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 2.6, delay: 1 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionAddressPertama}
          </motion.p>
          <motion.div
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 3, delay: 1.5 }}
            variants={textVariants}
          >
            <Btn
              title={'Google Maps'}
              style={{
                backgroundColor: section_agenda.agendaSectionButtonColor,
                color: section_agenda.agendaSectionButtonTextColor,
              }}
              className="w-9/12 text-xl my-5 py-2"
              onClick={() =>
                window.open(section_agenda.agendaSectionMapsPertama, '_blank')
              }
            />
          </motion.div>
          <motion.p
            className="mt-5 font-bold"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1.4, delay: 0.7 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionAcaraKedua}
          </motion.p>
          <motion.p
            className="mb-5"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1.8, delay: 0.8 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionHoursKedua}
          </motion.p>
          <motion.p
            className="font-bold"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 2.2, delay: 0.9 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionLocationKedua}
          </motion.p>
          <motion.p
            className="font-light"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 2.6, delay: 1 }}
            variants={textVariants}
          >
            {section_agenda.agendaSectionAddressKedua}
          </motion.p>
          <motion.div
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 3, delay: 1.5 }}
            variants={textVariants}
          >
            <Btn
              title={'Google Maps'}
              style={{
                backgroundColor: section_agenda.agendaSectionButtonColor,
                color: section_agenda.agendaSectionButtonTextColor,
              }}
              className="w-9/12 text-xl my-5 py-2"
              onClick={() =>
                window.open(section_agenda.agendaSectionMapsKedua, '_blank')
              }
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Location;
