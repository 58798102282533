import TransitonCardTwo from '../transition/cardTwobanner';
import { AnimatePresence } from 'framer-motion';
import { GlobalContext } from '../../config/context/global';
import { MarriageContext } from '../../config/context/marriagedCard';
import './scroll.css';
import { useContext, useEffect, useRef } from 'react';
import { formatDate } from '../../utils/formatDate';

const TransitionBanner = ({
  isTransitionVisible,
  handleClose,
  setIsPlaying,
}) => {
  return (
    <AnimatePresence mode="wait">
      {isTransitionVisible && (
        <TransitonCardTwo onClose={handleClose} setIsPlaying={setIsPlaying} />
      )}
    </AnimatePresence>
  );
};

function Section({
  isTransitionVisible,
  setIsTransitionVisible,
  handleClose,
  setIsPlaying,
  isCover,
}) {
  const { globalCtx } = useContext(GlobalContext);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_intro } = marriageCardData;
  const divRef = useRef(null);

  if (!isCover) {
    setIsTransitionVisible(false);
  }
  const { day, date, year } = formatDate(marriageCardData.unixWeddingDate, '');
  useEffect(() => {
    const currentRef = divRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          window.scrollTo(0, 0);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <div
      ref={divRef}
      className="h-screen"
      style={{
        backgroundColor: section_intro.introSectionBackgroundColor,
        backgroundImage: section_intro.isIntroSectionUsingImage
          ? `url('${section_intro.introSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <TransitionBanner
        isTransitionVisible={isTransitionVisible}
        handleClose={handleClose}
        setIsPlaying={setIsPlaying}
      />
      <div className="text-white text-start md:text-center md:py-28 px-5 md:px-0 h-screen relative top-1/4 md:top-[40%]">
        <p
          className="text-3xl md:text-3xl font-allura"
          style={{ color: section_intro.introSectionDescriptionColor }}
        >
          {section_intro.introSectionTitleText}
        </p>
        <h1
          className="text-6xl font-extrabold my-6 tracking-[-3.7px] font-poppins"
          style={{ color: section_intro.introSectionTitleColor }}
        >
          {globalCtx.groomName} & {globalCtx.brideName}
        </h1>
        {/* <p className='py-3 md:w-2/5 md:mx-auto '>
			{marriageCardData.loveStorySectionText ||
			  `<b>Colossians 3:14 (TB):</b>
			“And over all these things, put on love, which binds them all together in perfect unity. Let
			the peace of Christ rule in your hearts, since as members of one body you were called to peace.
			And be thankful.”`}
		  </p> */}
        <div
          className="flex justify-between md:px-0 md:w-1/2 items-center md:mx-auto"
          style={{ color: section_intro.introSectionDescriptionColor }}
        >
          <div className="flex flex-col my-0">
            <p>DAY</p>
            <p className="-my-2">{day.toUpperCase() || 'SATURDAY'}</p>
          </div>
          <div>
            <p>DATE</p>
            <p className="-my-2">{date.toUpperCase() || 'ELEVEN'}</p>
          </div>
          <div>
            <p>YEAR</p>
            <p className="-my-2">{year || '2023'}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
