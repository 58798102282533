import { useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../config/context/global';
import { SubscriptionContext } from '../config/context/subscription';
import { TemplateContext } from '../config/context/template';
import { MarriageContext } from '../config/context/marriagedCard';
import { getById } from '../api';

// layout
import TemplateOne from '../layout/template/cardOne';
import TemplateTwo from '../layout/template/cardTwo';
import TemplateThree from '../layout/template/cardThree';

const template1 = process.env.REACT_APP_TEMPLATE_ONE_ID;
const template2 = process.env.REACT_APP_TEMPLATE_TWO_ID;
const template3 = process.env.REACT_APP_TEMPLATE_THREE_ID;

function Invite() {
  const { user, invitedUser } = useParams();
  const navigate = useNavigate();
  const { globalAct } = useContext(GlobalContext);
  const { setSubscriptionData } = useContext(SubscriptionContext);
  const { setTemplateData } = useContext(TemplateContext);
  const { setMarriageCardData } = useContext(MarriageContext);
  const [userData, setUserData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getById({
          url: `marriage-card/${user}/invites/${invitedUser}`,
        });
        const { marriageCard, peopleInvited } = userData;
        setUserData(marriageCard);
        globalAct.setInvitedPeople(invitedUser);
        globalAct.setBrideName(
          marriageCard.section_bride.brideSectionBrideName
        );
        globalAct.setGroomName(
          marriageCard.section_groom.groomSectionGroomName
        );
        globalAct.setPeopleInvitedData(peopleInvited);
        setSubscriptionData(marriageCard.master_subscription_plan);
        setTemplateData(marriageCard.master_template_card);
        setMarriageCardData(marriageCard);

        // document
        //   .querySelector('meta[property="description"]')
        //   .setAttribute(
        //     'content',
        //     marriageCard?.section_prewedding?.preweddingSectionDescription ||
        //       'Default description'
        //   );
        // document.title =
        //   marriageCard?.section_prewedding?.preweddingSectionTitleText ||
        //   'Lovephoria';
        // document
        //   .querySelector('meta[property="title"]')
        //   .setAttribute(
        //     'content',
        //     marriageCard?.section_prewedding?.preweddingSectionTitleText ||
        //       'Default Title'
        //   );
        // document
        //   .querySelector('meta[property="image"]')
        //   .setAttribute(
        //     'content',
        //     marriageCard?.thumbnailBackgroundImage ||
        //       'https://storage.googleapis.com/lovephoria/IMG_5783.JPG'
        //   );
        // document
        //   .querySelector('meta[property="type"]')
        //   .setAttribute('content', 'website');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 404
        ) {
          if (invitedUser !== 'Example') {
            navigate('/404'); // Redirect to the 404 page
          }
        } else {
          navigate('/404'); // Redirect to the 404 page
        }
      }
    };
    fetchData();

    /* Update on resize for mobile devices */
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 1}px`
      );
    });
  }, [
    invitedUser,
    user,
    navigate,
    setSubscriptionData,
    setTemplateData,
    setMarriageCardData,
  ]);

  return (
    <div>
      {userData && userData.master_template_card._id === template1 && (
        <TemplateOne />
      )}
      {userData && userData.master_template_card._id === template2 && (
        <TemplateTwo />
      )}
      {userData && userData.master_template_card._id === template3 && (
        <TemplateThree />
      )}
    </div>
  );
}

export default Invite;
