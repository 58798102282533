import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { MarriageContext } from '../../config/context/marriagedCard';
import './scroll.css';

const textVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0 },
};

function LoveStore() {
  const ref = useRef(null);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_love_story } = marriageCardData;
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after the first intersection
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <div
      className="h-screen"
      style={{
        backgroundColor: section_love_story.loveStorySectionBackgroundColor,
        backgroundImage: section_love_story.isLoveStorySectionUsingImage
          ? `url('${section_love_story.loveStorySectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="text-start text-white px-10 md:px-20 h-screen relative py-24">
        <div className="flex flex-col w-full md:w-2/5 lg:w-2/5 bg-[#2E2E2EB8] max-h-[90vh] p-5 rounded-lg">
          <h1 className="text-6xl font-bold text-orange-400">LOVE</h1>
          <h1 className="text-6xl font-bold text-orange-400">STORY</h1>
          <motion.div
            ref={ref}
            className="flex flex-col gap-5 max-h-screen mt-5 text-sm z-30 overflow-y-auto text-wrap custom-scrollbar2"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 2.5, delay: 0.2 }}
            variants={textVariants}
          >
            <p style={{ color: section_love_story.loveStorySectionTitleColor }}>
              {section_love_story.loveStorySectionTitleText}
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default LoveStore;
