import React, { useEffect, useState } from 'react';

const DropdownInputContact = ({
	title = 'Choose a topic:',
	value,
	name,
	defaultValue,
	className = '',
	onChange,
	style,
	setForm,
	formData,
}) => {
	const [selectedOption, setSelectedOption] = useState('-');
	useEffect(() => {
		setSelectedOption(defaultValue);
	}, [value, defaultValue]);

	const handleSelectChange = (event) => {
		const newValue = event.target.value;
		setSelectedOption(newValue);
		setForm((prevForm) => ({
			...prevForm,
			[name]: newValue,
		}));
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<div className="flex flex-col h-fit text-start my-3">
			{title && (
				<label
					htmlFor="dropdown"
					className="font-medium"
				>
					{title}
				</label>
			)}
			<select
				id="dropdown"
				name={name}
				value={selectedOption || ''}
				onChange={handleSelectChange}
				className={`${className} py-3 px-2 focus:outline-none`}
				style={style}
			>
				{Array.isArray(value) ? (
					value.map((item) => (
						<option
							key={item.name || item.subscriptionName}
							value={item._id}
							className="bg-gray text-black text-lg "
						>
							{item.name || item.subscriptionName}
						</option>
					))
				) : (
					<option
						key={value?.name || value?.subscriptionName}
						value={value?._id}
						className="bg-gray text-black text-lg "
					>
						{value?.name || value?.subscriptionName}
					</option>
				)}
			</select>
		</div>
	);
};

export default DropdownInputContact;
