import { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

const textVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

function Footer() {
  const ref = useRef(null);
  const { marriageCardData } = useContext(MarriageContext);
  const { section_footer } = marriageCardData;
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after the first intersection
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      className="w-full h-screen relative"
      style={{
        backgroundColor: section_footer.footerSectionBackgroundColor,
        backgroundImage: section_footer.isFooterSectionUsingImage
          ? `url('${section_footer.footerSectionBackgroundImage}')`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        scrollSnapAlign: 'center',
      }}
    >
      <div
        ref={ref}
        className="h-screen p-8 pb-32 md:pb-7 flex flex-col justify-between"
      >
        <div className="text-center">
          <motion.h1
            className="text-4xl font-bold"
            style={{ color: section_footer.footerSectionTitleColor }}
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.4 }}
            variants={textVariants}
          >
            {section_footer.footerSectionTitleText}
          </motion.h1>
          <motion.p
            className="text-md font-normal my-2"
            style={{ color: section_footer.footerSectionDescriptionColor }}
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 0.6 }}
            variants={textVariants}
          >
            {section_footer.footerSectionDescription}
          </motion.p>
        </div>
        <div className="text-start font-bold text-white">
          <motion.p
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1 }}
            variants={textVariants}
          >
            Our friendly team would love to hear from you.
          </motion.p>
          <motion.div
            className="flex my-5"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1.3 }}
            variants={textVariants}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              className="text-white"
            />
            <div className="flex-col ml-3 my-auto">
              <p>lovephoriaid@gmail.com</p>
            </div>
          </motion.div>
          <motion.div
            className="flex my-5"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1.6 }}
            variants={textVariants}
          >
            <FontAwesomeIcon icon={faPhone} size="lg" className="text-white" />
            <div className="flex-col ml-3 my-auto">
              <p>+62 821-4434-6975</p>
            </div>
          </motion.div>
          <motion.div
            className="flex my-5"
            initial="hidden"
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ duration: 1, delay: 1.9 }}
            variants={textVariants}
          >
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              size="lg"
              className="text-white"
            />
            <div className="flex-col ml-3 my-auto">
              <p>Denpasar, Bali</p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
